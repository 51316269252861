<script>
export default {
  name: 'PaymentSuccess',
  data() {
    return {
      path: false,
    };
  },
  mounted() {
    this.path = `/view/${this.$route.query.entityType}/${this.$route.query.entityId}`;
    window.setTimeout(() => {
      window.top.location.href = this.path;
    }, 5000);
  },
};
</script>

<template>
  <div style="text-align: center" :v-show="path">
    Your payment has been successfully registered, please wait whilst we redirect you to your case...
  </div>
</template>
